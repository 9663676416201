.policy-wrapper {
  background-color: #fff;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.policy-wrapper h1 {
  color: black !important;
  text-align: center;
}

.policy-wrapper p {
  color: black !important;
  /* text-align: center; */
  width: 70%;
  margin-top: 40px;
  font-weight: 600;
  border: 2px dotted black;
  padding: 20px;
}
