.welcome {
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: var(--color-bg);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: grid;
  place-items: center;
  transition: 1s all;
}

.welcome-anim {
  animation: welcomeAnim 2s;
}

.welcome-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.welcome p {
  font-size: 30px;

  transition: 1s all;
}

.welcome .logo {
  animation: rotate 2s;
}

@keyframes rotate {
  0% {
    transform: scale(1);
    transform: rotate(0deg);
  }

  100% {
    transform: scale(100.4);
    /* transform: rotate(180deg); */
    opacity: 0;
  }
}

.welcome .hidelogo {
  display: none;
}

@keyframes welcomeAnim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* =================== MEDIA QUERY (SMALL DEVICES) ===================== */

@media screen and (max-width: 600px) {
  .header {
    height: 100vh;
    padding-top: 6rem;
  }

  .header__socials,
  .scrool__down {
    display: none;
  }
}
